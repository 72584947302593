@use 'color-palette' as colors;
@use 'theme-variables' as variables;

:root {
    .theme-ce73639d1410947809723b5a1b19f32a {
        // munit.io specific theme here
        --font-size-login-screen-title: #{variables.$font-size-login-screen-title};
        --background-img-login-screen: #{variables.$background-img-login-screen};
    }
}
