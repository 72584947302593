@use 'color-palette' as colors;
@use 'theme-variables' as variables;

:root {
    --background-blur-login-form: #{variables.$background-blur-login-form};
    --color-action-items-highlight: #{colors.$green-light-2};
    --color-admin-panel-item-background: #{colors.$white};
    --color-alert-background: #{colors.$red-a15};
    --color-alert: #{colors.$red};
    --color-app-header-border-background: #{colors.$white-a12};
    --color-background-feed-header: #{colors.$black-firefly};
    --color-background-feed-subheader: #{colors.$black-firefly-a15};
    --color-background-primary: #{colors.$white};
    --color-background-secondary: #{colors.$grey-light};
    --color-modal-overlay: #{colors.$black-firefly-a40};
    --color-border-form-controls: #{colors.$grey-a12};
    --color-border-primary: #{colors.$grey-a12};
    --color-border-secondary: #{colors.$grey-light};
    --color-button-active-background: #{colors.$black-firefly};
    --color-button-secondary-background-hover: #{colors.$black-firefly};
    --color-button-secondary-background: #{darken(colors.$grey-light, 2.35%)};
    --color-button-secondary-text-hover: #{colors.$white};
    --color-button-text: #{colors.$white};
    --color-card-background: #{colors.$white};
    --color-card-labels-text: #{colors.$black-firefly};
    --color-category-name: #{colors.$black-firefly};
    --color-confirm-highlight: #{colors.$green-dark};
    --color-dark-background: #{colors.$black-firefly};
    --color-disabled-overlay: #{colors.$grey-light};
    --color-discover-item-background: #{colors.$black-firefly-a15};
    --color-discover-item-text: #{colors.$white};
    --color-feed-search: #{colors.$grey-light};
    --color-font-category-name: #{colors.$green-dark-2};
    --color-checkmark: #{colors.$green-dark-2};
    --color-font-item-active: #{colors.$green-light-2};
    --color-font-primary: #{colors.$white};
    --color-font-secondary-light: #{colors.$black-firefly};
    --color-font-secondary-transparent: #{colors.$blue-light-a40};
    --color-font-secondary: #{colors.$blue-light};
    --color-font-text-content: #{colors.$black-firefly};
    --color-font-query-preview: #{colors.$grey-silver-a50};
    --color-font-hint: #{colors.$grey-a40};
    --color-green-dark: #{colors.$green-dark};
    --color-spinner-light: #{colors.$green-dark};
    --color-image-uploader-background: #{colors.$grey-light};
    --color-input-text: #{colors.$grey-a26};
    --color-item-active-background: #{colors.$black-firefly};
    --color-label-selected-background: #{colors.$blue-aquamarine};
    --color-left-menu-background: #{colors.$black-firefly};
    --color-left-menu-category-background-hover: #{colors.$green-dark-a15};
    --color-left-menu-channel-background-active: #{colors.$green-dim};
    --color-left-menu-channel-background-hover: #{colors.$green-dark-a15};
    --color-left-menu-text-active: #{colors.$white};
    --color-left-menu-text: #{colors.$white-a60};
    --color-organisation-menu-background-hover: #{colors.$green-dark-a15};
    --color-organisation-menu-background-active: #{colors.$green-dark-a05};
    --color-unread-animation-start: #{colors.$white-a25};
    --color-unread-animation-end: #{colors.$white-a45};
    --color-login-form-background: #{colors.$black-firefly};
    --color-post-link-text: #{colors.$green-light-2};
    --color-slide-toggle-bar: #{colors.$black-firefly};
    --font-family-primary: #{variables.$font-family-oxygen};
    --font-family-secondary: #{variables.$font-family-jetbrains};
    --font-size-login-screen-title: #{variables.$font-size-login-screen-title};
    --img-width-login-screen-title: #{variables.$img-width-login-screen-title};
    --color-login-submit-button: #{colors.$black-firefly};
    --color-login-button-background: #{colors.$blue-light};
    --color-slide-toggle-button: #{colors.$grey-a26};
    --color-slide-toggle-button-thumb: #{colors.$grey-a20};
    --color-slide-toggle-button: #{colors.$green-light-2-a62};
    --color-credit-rates-table-header: #{colors.$blue-aquamarine};
    --color-slide-toggle-button-thumb: #{colors.$green-light-2};
    --color-toggle-background: #{colors.$green-light-2-a14};
    --color-icon: #{colors.$black-firefly};
    --color-icon-hover: #{colors.$white};
    --color-post-action-btns: #{colors.$black-firefly};
    --color-background-post-action-btns: #{colors.$black-firefly-a15};
    --color-post-action-btns-hover: #{colors.$white};
    --color-background-post-action-btns-hover: #{colors.$black-firefly};
    --color-user-details-icon: #{colors.$blue-light};
    --color-section-divider-light: #{colors.$grey-silver-a20};
    --color-section-divider: #{colors.$grey-silver-a50};
    --color-section-divider-dark: #{colors.$grey-silver};
    --color-scrollbar: #{colors.$black-firefly-a15};
    --color-scrollbar-opaque: #{colors.$grey-medium};
    --color-silo-select-hover-background: #{colors.$green-dark-a15};
    --color-locked-item-background: #{colors.$grey-silver-a20};
    --color-search-stopped: #{colors.$yellow-a30};
    --color-search-stopped-light: #{colors.$yellow-a20};
    --color-crawled-disabled-warning: #{colors.$yellow};
    --color-dark-dropdown-shadow: #{colors.$green-light-2-a14};
}
