@use 'theming/theme';
@use 'variables';

.form_btns {
    display: flex;
    justify-content: flex-end;

    .right-side-group {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    .mat-mdc-unelevated-button {
        background-color: transparent;
    }

    .mat-mdc-icon-button {
        line-height: 32px;
        border-radius: 4px;
    }
}

.btn {
    cursor: pointer;

    &:not(.btn-link, .add_post) {
        font-size: 14px;
        line-height: 30px;
        height: 32px;
        flex-shrink: 0;
        letter-spacing: 1.25px;
        padding: 0 10px;
        border-radius: 4px;
        min-width: 88px;

        .mat-icon {
            margin-right: 4px;
            line-height: 16px;
        }
    }

    &.primary_btn,
    &.cancel-button,
    &.delete {
        font-weight: bold;
        text-transform: uppercase;
        line-height: 28px;
    }

    &.primary_btn.mat-mdc-raised-button {
        color: theme.$color-button-text;
    }
    &.primary_btn.mat-mdc-raised-button:not(:disabled) {
        background-color: theme.$color-button-active-background;
        border: solid 1px theme.$color-border-primary;

        &:hover {
            color: theme.$color-button-text;
        }

        .mat-icon {
            font-size: 16px;
            margin-right: 8px;
            line-height: 20px;
        }

        .mat-mdc-progress-spinner.btn-spinner {
            margin: 0 auto;
        }
    }

    &.delete,
    &.delete.mdc-button {
        background-color: transparent;
        color: theme.$color-alert;

        &:hover {
            color: theme.$color-alert;
        }

        .mat-mdc-progress-spinner {
            margin: auto;
        }

        .mat-icon {
            position: relative;
            top: -1px;
        }

        .mat-mdc-button-persistent-ripple {
            display: none;
        }
    }

    &.dashed {
        height: 30px;
        background-color: transparent;
        border: 1px solid theme.$color-font-text-content;
        border-style: dashed;
        letter-spacing: 0.25px;

        &:hover {
            border-color: theme.$color-font-text-content;
        }

        .mat-icon {
            line-height: 20px;
            font-size: 14px;
        }
    }

    &.cancel-button {
        margin-right: 30px;

        &.float-right {
            margin-right: 0;
        }
    }

    &.delete {
        margin-right: 15px;
    }
}

.button-link {
    color: theme.$color-action-items-highlight;
    border: none;
    outline: none;
    background-color: transparent;
    padding: 0;
}

.styled-scrollbar .mat-mdc-dialog-container,
.mat-mdc-dialog-surface,
.styled-scrollbar {
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px theme.$color-scrollbar;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: theme.$color-scrollbar;
        outline: 1px solid theme.$color-scrollbar;
    }

    &-transparent {
        overflow: overlay;
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
        }
        &::-webkit-scrollbar-thumb {
            background-color: theme.$color-scrollbar-opaque;
            outline: 1px solid theme.$color-scrollbar-opaque;
        }
    }

    &-no-shadow {
        &::-webkit-scrollbar-track {
            box-shadow: none;
        }
    }

    &-thin {
        &::-webkit-scrollbar {
            width: 6px;
        }
    }
}

.category-item {
    display: inline-block;
    background-color: transparent;
    border: solid 1px theme.$color-font-text-content;
    border-radius: 16px;
    font-size: 12px;
    padding: 6px 10px;
    margin-right: 5px;
    cursor: default;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    line-height: 1em;
    margin-bottom: 6px;
    position: relative;

    &.size-lg {
        font-size: 14px;
        padding: 7px 10px 6px;
    }

    .edit-icon {
        font-size: 13px;
        position: absolute;
        left: 50%;
        top: 7px;
        opacity: 0;
        transition: opacity 0.3s;
    }

    &.editable {
        cursor: pointer;

        &:hover {
            color: transparent;

            .edit-icon {
                opacity: 1;
                color: theme.$color-font-text-content;
            }
        }
    }

    &.interactable {
        cursor: pointer;
    }
}

.selected_tags_list {
    li {
        display: inline-block;
        list-style: none;
    }
}

.title {
    font-size: 24px;
    font-weight: bold;
}

/*-------- Multiple select input ---------*/
.search-input-form-field {
    .mat-mdc-form-field-subscript-wrapper {
        display: none;
    }
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: transparent;
    }

    .mat-mdc-form-field-infix {
        width: auto;
    }

    .mdc-line-ripple {
        display: none;
    }

    .mdc-text-field {
        padding: 0;
    }

    .mat-mdc-form-field-focus-overlay {
        display: none;
    }
}
