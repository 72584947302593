@use 'theming/theme';
@use 'variables';
@use 'modals';
@use 'material-components';
@use 'shared-components';

html {
    width: 100%;
    height: 100%;
}

body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

a {
    text-decoration: none;
}

html,
body,
.app-content {
    height: 100%;
    font-family: theme.$font-family-primary;
}

h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.rotate-45 {
    transform: rotate(45deg);
}

.clear {
    clear: both;
}

.menu-wrapper {
    .dropdown-item:active,
    .dropdown-item:hover {
        color: theme.$color-font-text-content;
    }
}

/* ------------ Start forms ------------ */
input[type='text']::-ms-clear,
input[type='email']::-ms-clear {
    display: none;
}

.form-control:focus {
    border-color: rgba(0, 0, 0, 0.15);
    box-shadow: none;
}

.form-group {
    margin-bottom: 2.25rem;

    position: relative;
    input.error,
    select.error {
        border-color: theme.$color-alert;
    }
    .error-msg {
        color: theme.$color-alert;
    }
    .info-msg {
        i {
            vertical-align: text-bottom;
            font-size: 16px;
        }
    }
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
}

a:hover,
a:focus,
button:focus {
    text-decoration: none;
    outline: none;
}

.read {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: theme.$color-action-items-highlight;
    position: absolute;
    left: -13px;
}

.app-content {
    .mat-mdc-list {
        padding: 0;
        .mat-mdc-list-item {
            font-size: 12px;
        }
    }
}

.tooltip .tooltip-inner {
    font-size: 12px;
    white-space: nowrap;
}

/* ------------ End forms ------------ */
/* ------------ custom scrollbar ------------ */
.sb_box_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .sb_list_container {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        min-height: 0;
        .sb_element {
            position: relative;
            height: 100%;
            flex: 1 1 auto;
            width: 100%;
            .ps-content {
                min-height: auto;
            }
        }
    }
}

/* ------------ End custom scrollbar ------------ */

.silo-selected {
    font-size: 13px;
    padding-left: 6px;
    color: theme.$color-confirm-highlight;
}

.app-content .mat-mdc-list .mat-mdc-list-item [mat-list-avatar] {
    max-height: 34px;
    margin: 0 8px;
}

.ps .ps__rail-y {
    background-color: transparent !important;
}

.ps .ps__rail-y,
.ps .ps__thumb-y {
    width: 6px !important;
}

.help_dialog_modal {
    width: 80%;
}

#map,
#location_map {
    height: 100%;
}

#description {
    font-size: 15px;
    font-weight: 300;
}

#infowindow-content {
    display: none;
}

#map #infowindow-content {
    display: inline;
}

.pac-card {
    margin: 10px 10px 0 0;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: variables.$white_color;
}

#pac-container {
    padding-bottom: 12px;
    margin-right: 12px;
}

.pac-controls {
    display: inline-block;
    padding: 5px 11px;
}

.pac-controls label {
    font-size: 13px;
    font-weight: 300;
}

#title {
    color: variables.$white_color;
    background-color: theme.$color-action-items-highlight;
    font-size: 25px;
    font-weight: 500;
    padding: 6px 12px;
}
#target {
    width: 345px;
}

markdown {
    h1 {
        font-size: 2rem;
        margin-bottom: 1.3rem;
    }

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1.1rem;
    }

    h3 {
        font-size: 1.3rem;
        margin-bottom: 0.9rem;
    }

    h4 {
        font-size: 1.1rem;
        margin-bottom: 0.7rem;
    }

    h5 {
        font-size: 0.9rem;
        margin-bottom: 0.6rem;
    }

    h6 {
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
    }

    table {
        border-collapse: collapse;
        margin: 1rem 0;
    }

    table,
    td,
    th {
        border: 1px solid theme.$color-border-primary;
        padding: 3px 8px;
    }
}

.mat-mdc-cell .crown-icon {
    padding: 8px;

    svg {
        width: 23px;
        height: 23px;
    }
}

.tab-info-wrapper .crown-icon {
    svg {
        width: 24px;
        height: 24px;
    }
}

.left-menu {
    height: 100%;
}

a.tag_category_anchor {
    color: theme.$color-font-item-active !important;
}

.filter-options {
    .date-picker {
        margin-left: 0;
    }
}

.post_type_label {
    svg {
        max-height: 24px;
        max-width: 24px;
    }

    &.news {
        svg {
            max-height: 30px;
            max-width: 30px;
        }
    }
}

.header_wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 15px;
    justify-content: space-between;

    .title_wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
    }
}

.red {
    color: theme.$color-alert;
}

.material-icons {
    &.material-icons-outlined {
        font-family: 'Material Icons Outlined';
        font-weight: normal;
        font-style: normal;
        font-size: 24px;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-feature-settings: 'liga';
        -webkit-font-smoothing: antialiased;
    }
}

.cdk-overlay-pane {
    &.share-tab {
        min-width: 600px;
        max-width: 90vw;
    }
}

.mat-mdc-tooltip {
    max-width: 80vw !important;
    width: fit-content !important;
}

input {
    border-width: 1px;

    &.mat-mdc-input-element {
        box-sizing: border-box;
    }

    &.form-control {
        height: calc(1.5em + 0.75rem + 2px);
    }
}

.form-control {
    -webkit-appearance: auto;
    -moz-appearance: auto;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 variables.$white_color;
    opacity: 0.5;
}

.btn-link {
    text-decoration: none;
}

.row > * {
    width: auto;
}

.elevation-on-hover {
    transition: box-shadow 0.3s;

    &:hover {
        box-shadow:
            0px 5px 5px -3px rgba(0, 0, 0, 0.2),
            0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }
}

.organisation-dashboard .sidenav {
    display: none;
}

@media screen and (max-width: 768px) {
    .header_wrapper,
    .header_wrapper .title_wrapper {
        max-width: 100%;
    }
}

@media #{variables.$mobile} {
    .sm-display-none {
        display: none !important;
    }
}
