@use 'default/default.theme';
@use 'dockside/dockside.theme';
@use 'marketscape/marketscape.theme';
@use 'munitio/munitio.theme';
@use 'iptwins/iptwins.theme';
@use 'securesight/securesight.theme';

// These file contains all stylings that can be changed dynamicly. It allows to use scss variables across application instead of css vars.

$color-action-items-highlight: var(--color-action-items-highlight);
$color-admin-panel-item-background: var(--color-admin-panel-item-background);
$color-alert: var(--color-alert);
$color-alert-background: var(--color-alert-background);
$color-modal-overlay: var(--color-modal-overlay);
$color-border-form-controls: var(--color-border-form-controls);
$color-border-primary: var(--color-border-primary);
$color-app-header-border-background: var(--color-app-header-border-background);
$color-border-secondary: var(--color-border-secondary);
$color-scrollbar: var(--color-scrollbar);
$color-scrollbar-opaque: var(--color-scrollbar-opaque);
$color-button-active-background: var(--color-button-active-background);
$color-button-secondary-background-hover: var(--color-button-secondary-background-hover);
$color-button-secondary-background: var(--color-button-secondary-background);
$color-button-secondary-text-hover: var(--color-button-secondary-text-hover);
$color-button-text: var(--color-button-text);
$color-card-labels-text: var(--color-card-labels-text);
$color-card-background: var(--color-card-background);
$color-confirm-highlight: var(--color-confirm-highlight);
$color-disabled-overlay: var(--color-disabled-overlay);
$color-discover-item-background: var(--color-discover-item-background);
$color-discover-item-text: var(--color-discover-item-text);
$color-feed-search: var(--color-feed-search);
$color-font-secondary-light: var(--color-font-secondary-light);
$color-image-uploader-background: var(--color-image-uploader-background);
$color-input-text: var(--color-input-text);
$color-item-active-background: var(--color-item-active-background);
$color-locked-item-background: var(--color-locked-item-background);
$color-font-item-active: var(--color-font-item-active);
$color-label-selected-background: var(--color-label-selected-background);
$color-left-menu-background: var(--color-left-menu-background);
$color-left-menu-category-background-hover: var(--color-left-menu-category-background-hover);
$color-left-menu-channel-background-hover: var(--color-left-menu-channel-background-hover);
$color-left-menu-channel-background-active: var(--color-left-menu-channel-background-active);
$color-left-menu-text-active: var(--color-left-menu-text-active);
$color-left-menu-text: var(--color-left-menu-text);
$color-organisation-menu-background-hover: var(--color-organisation-menu-background-hover);
$color-organisation-menu-background-active: var(--color-organisation-menu-background-active);
$color-unread-animation-start: var(--color-unread-animation-start);
$color-unread-animation-end: var(--color-unread-animation-end);
$color-post-link-text: var(--color-post-link-text);
$color-slide-toggle-bar: var(--color-slide-toggle-bar);
$color-slide-toggle-button: var(--color-slide-toggle-button);
$color-slide-toggle-button-thumb: var(--color-slide-toggle-button-thumb);
$color-toggle-background: var(--color-toggle-background);
$color-section-divider: var(--color-section-divider);
$color-section-divider-dark: var(--color-section-divider-dark);
$color-section-divider-light: var(--color-section-divider-light);
$background-login-submit-button: var(--color-login-button-background);
$color-login-submit-button: var(--color-login-submit-button);
$background-login-submit-button-webkit: var(--color-login-button-background-webkit);
$color-spinner-light: var(--color-spinner-light);
$color-dark-dropdown-shadow: var(--color-dark-dropdown-shadow);

$font-family-primary: var(--font-family-primary);
$font-family-secondary: var(--font-family-secondary);
$font-size-login-screen-title: var(--font-size-login-screen-title);
$img-width-login-screen-title: var(--img-width-login-screen-title);
$background-img-login-screen: var(--background-img-login-screen);

$color-background-primary: var(--color-dark-background);
$color-login-form-background: var(--color-login-form-background);
$background-blur-login-form: var(--background-blur-login-form);
$color-background-secondary: var(--color-background-secondary);
$color-background-modal: var(--color-background-primary);
$color-font-primary: var(--color-font-primary);
$color-font-secondary: var(--color-font-secondary);
$color-font-secondary-transparent: var(--color-font-secondary-transparent);
$color-font-text-content: var(--color-font-text-content);
$color-font-category-name: var(--color-font-category-name);
$color-font-query-preview: var(--color-font-query-preview);
$color-font-hint: var(--color-font-hint);
$color-trial-days-background: var(--color-font-category-name);
$color-checkmark: var(--color-checkmark);
$color-icon: var(--color-icon);
$color-icon-hover: var(--color-icon-hover);
$color-user-details-icon: var(--color-user-details-icon);
$color-background-feed-header: var(--color-background-secondary);
$color-background-feed-subheader: var(--color-background-secondary);
$color-background-post-action-btns: var(--color-background-post-action-btns);
$color-post-action-btns: var(--color-post-action-btns);
$color-background-post-action-btns-hover: var(--color-background-post-action-btns-hover);
$color-post-action-btns-hover: var(--color-post-action-btns-hover);
$color-silo-select-hover-background: var(--color-silo-select-hover-background);
$color-search-stopped: var(--color-search-stopped);
$color-search-stopped-light: var(--color-search-stopped-light);
$color-crawled-disabled-warning: var(--color-crawled-disabled-warning);
$color-credit-rates-table-header: var(--color-credit-rates-table-header);
