$white_color: #fff;
$overlay-color: #f5f7fa;
$dropdown_active_color: #f7f7f9;
$black-color: #000;

$news_type_color: #487ece;
$news_type_bg_color: rgba(72, 126, 206, 0.1);

$marketplace_type_color: #f36f0a;
$marketplace_type_bg_color: rgba(243, 111, 10, 0.1);

$social_media_type_color: #1da1f3;
$social_media_type_bg_color: rgba(29, 161, 243, 0.1);

$forum_type_color: #c9b242;
$forum_type_bg_color: rgba(183, 201, 17, 0.1);

$credit_card_type_color: #49c383;
$credit_card_type_bg_color: rgba(73, 195, 131, 0.1);

$databreach_type_color: #f3360a;
$databreach_type_bg_color: rgba(243, 54, 10, 0.1);

$leaks_type_color: #de5151;
$leaks_type_bg_color: rgba(233, 78, 43, 0.1);

$datadump_type_color: #6f3ac9;
$datadump_type_bg_color: rgba(111, 58, 201, 0.1);

$whois_type_color: rgb(146, 212, 255);
$whois_type_bg_color: rgba(163, 212, 247, 0.15);

$shodan_type_color: #336f69;
$shodan_type_bg_color: #bbcfcb;

$darkweb_type_color: #154205;
$darkweb_type_bg_color: #a8a8a8;

$multiple_type_color: #65b1ee;

$score_red_color: #d21b49;
$score_green_color: #10c478;
$score_yellow_color: #ebc033;

$risk_red_color: #d21b49;
$risk_green_color: #10c478;
$risk_yellow_color: #ebc033;

$desktop_xL: 'screen and (min-width: 1401px) and (max-width: 1600px)';
$desktop_md: 'screen and (min-width: 1201px) and (max-width: 1400px)';
$desktop_max1092: 'screen and (max-width: 1092px)';
$desktop: 'screen and (min-width: 993px) and (max-width: 1200px)';
$tablet: 'screen and (min-width: 768px) and (max-width: 992px)';
$mobile: 'screen and (max-width: 767px)';

$left-menu-width: 220px;
