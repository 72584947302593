/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/assets/fonts/open-sans-v15-latin-300.eot'); /* IE9 Compat Modes */
    src:
        local('Open Sans Light'),
        local('OpenSans-Light'),
        url('/assets/fonts/open-sans-v15-latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/open-sans-v15-latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-300.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/assets/fonts/open-sans-v15-latin-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-300italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: url('/assets/fonts/open-sans-v15-latin-300italic.eot'); /* IE9 Compat Modes */
    src:
        local('Open Sans Light Italic'),
        local('OpenSans-LightItalic'),
        url('/assets/fonts/open-sans-v15-latin-300italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/open-sans-v15-latin-300italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-300italic.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-300italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/assets/fonts/open-sans-v15-latin-300italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/assets/fonts/open-sans-v15-latin-regular.eot'); /* IE9 Compat Modes */
    src:
        local('Open Sans Regular'),
        local('OpenSans-Regular'),
        url('/assets/fonts/open-sans-v15-latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/open-sans-v15-latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/assets/fonts/open-sans-v15-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('/assets/fonts/open-sans-v15-latin-italic.eot'); /* IE9 Compat Modes */
    src:
        local('Open Sans Italic'),
        local('OpenSans-Italic'),
        url('/assets/fonts/open-sans-v15-latin-italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/open-sans-v15-latin-italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-italic.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/assets/fonts/open-sans-v15-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/assets/fonts/open-sans-v15-latin-600.eot'); /* IE9 Compat Modes */
    src:
        local('Open Sans SemiBold'),
        local('OpenSans-SemiBold'),
        url('/assets/fonts/open-sans-v15-latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/open-sans-v15-latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-600.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/assets/fonts/open-sans-v15-latin-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-600italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('/assets/fonts/open-sans-v15-latin-600italic.eot'); /* IE9 Compat Modes */
    src:
        local('Open Sans SemiBold Italic'),
        local('OpenSans-SemiBoldItalic'),
        url('/assets/fonts/open-sans-v15-latin-600italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/open-sans-v15-latin-600italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-600italic.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-600italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/assets/fonts/open-sans-v15-latin-600italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/assets/fonts/open-sans-v15-latin-700.eot'); /* IE9 Compat Modes */
    src:
        local('Open Sans Bold'),
        local('OpenSans-Bold'),
        url('/assets/fonts/open-sans-v15-latin-700.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/open-sans-v15-latin-700.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-700.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-700.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/assets/fonts/open-sans-v15-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-700italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url('/assets/fonts/open-sans-v15-latin-700italic.eot'); /* IE9 Compat Modes */
    src:
        local('Open Sans Bold Italic'),
        local('OpenSans-BoldItalic'),
        url('/assets/fonts/open-sans-v15-latin-700italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/open-sans-v15-latin-700italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-700italic.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-700italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/assets/fonts/open-sans-v15-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('/assets/fonts/open-sans-v15-latin-800.eot'); /* IE9 Compat Modes */
    src:
        local('Open Sans ExtraBold'),
        local('OpenSans-ExtraBold'),
        url('/assets/fonts/open-sans-v15-latin-800.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/open-sans-v15-latin-800.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-800.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-800.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/assets/fonts/open-sans-v15-latin-800.svg#OpenSans') format('svg'); /* Legacy iOS */
}
/* open-sans-800italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: url('/assets/fonts/open-sans-v15-latin-800italic.eot'); /* IE9 Compat Modes */
    src:
        local('Open Sans ExtraBold Italic'),
        local('OpenSans-ExtraBoldItalic'),
        url('/assets/fonts/open-sans-v15-latin-800italic.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('/assets/fonts/open-sans-v15-latin-800italic.woff2') format('woff2'),
        /* Super Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-800italic.woff') format('woff'),
        /* Modern Browsers */ url('/assets/fonts/open-sans-v15-latin-800italic.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('/assets/fonts/open-sans-v15-latin-800italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* Roboto-300 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    src:
        local('Roboto Light'),
        local('Roboto-Light'),
        /* Modern Browsers */ url('/assets/fonts/Roboto-Light.ttf') format('truetype');
}
/* Roboto-300italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 300;
    src:
        local('Roboto Light Italic'),
        local('Roboto-LightItalic'),
        /* Modern Browsers */ url('/assets/fonts/Roboto-LightItalic.ttf') format('truetype');
}
/* Roboto-regular - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    src:
        local('Roboto Regular'),
        local('Roboto-Regular'),
        /* Modern Browsers */ url('/assets/fonts/Roboto-Regular.ttf') format('truetype');
}
/* Roboto - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 400;
    src:
        local('Roboto Italic'),
        local('Roboto-Italic'),
        /* Modern Browsers */ url('/assets/fonts/Roboto-Italic.ttf') format('truetype');
}
/* Roboto-600 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    src:
        local('Roboto Medium'),
        local('Roboto-Medium'),
        /* Modern Browsers */ url('/assets/fonts/Roboto-Medium.ttf') format('truetype');
}
/* Roboto-600italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 500;
    src:
        local('Roboto Medium Italic'),
        local('Roboto-MediumItalic'),
        /* Modern Browsers */ url('/assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
}
/* Roboto-700 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    src:
        local('Roboto Bold'),
        local('Roboto-Bold'),
        /* Modern Browsers */ url('/assets/fonts/Roboto-Bold.ttf') format('truetype');
}
/* Roboto-700italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 700;
    src:
        local('Roboto Bold Italic'),
        local('Roboto-BoldItalic'),
        /* Modern Browsers */ url('/assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
}
/* Roboto-900 - latin */
@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 900;
    src:
        local('Roboto Black'),
        local('Roboto-Black'),
        /* Modern Browsers */ url('/assets/fonts/Roboto-Black.ttf') format('truetype');
}
/* Roboto-900italic - latin */
@font-face {
    font-family: 'Roboto';
    font-style: italic;
    font-weight: 900;
    src:
        local('Roboto Black Italic'),
        local('Roboto-Black'),
        /* Modern Browsers */ url('/assets/fonts/Roboto-BlackItalic.ttf') format('truetype');
}

/* JetBrainsMono - bold */
@font-face {
    font-family: 'JetBrainsMono';
    font-style: normal;
    font-weight: bold;
    src:
        local('JetBrainsMono Bold'),
        local('JetBrainsMono-Bold'),
        /* Modern Browsers */ url('/assets/fonts/JetBrainsMono-Bold.ttf') format('truetype'),
        url('/assets/fonts/JetBrainsMono-Bold.woff2') format('woff2');
}

/* Oxygen - regular */
@font-face {
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: normal;
    src:
        local('Oxygen Regular'),
        local('Oxygen-Regular'),
        /* Modern Browsers */ url('/assets/fonts/Oxygen-Regular.ttf') format('truetype');
}
/* Oxygen - bold */
@font-face {
    font-family: 'Oxygen';
    font-style: normal;
    font-weight: bold;
    src:
        local('Oxygen Bold'),
        local('Oxygen-Bold'),
        /* Modern Browsers */ url('/assets/fonts/Oxygen-Bold.ttf') format('truetype');
}
