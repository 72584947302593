@use 'theming/theme';
@use 'variables';
@use 'mixins';

@media (min-width: 576px) {
    .modal.show .modal-dialog {
        left: calc(#{variables.$left-menu-width} / 2);
    }

    .modal-sm {
        max-width: 470px;
    }

    .modal-lg {
        max-width: 870px;
    }

    .modal-dialog {
        margin: 9rem auto;
    }

    .cdk-overlay-pane {
        &.modal-lg,
        &.modal-sm {
            transform: translateX(calc(#{variables.$left-menu-width} / 2));
        }
        &.modal-sm {
            width: 470px;
        }
        &.modal-lg {
            width: 870px;
        }
        &.modal-h90 {
            height: 90vh;
        }
    }
}

.cdk-overlay-pane.mat-mdc-dialog-panel {
    max-height: 90vh;
}

.cdk-overlay-dark-backdrop,
.modal-backdrop {
    background-color: theme.$color-modal-overlay;
}

.modal-backdrop.show {
    opacity: 1;
}

.modal-sm {
    .mat-mdc-dialog-surface {
        padding: 30px 40px;
    }

    &.delete-silo-modal {
        .mat-mdc-dialog-surface {
            padding: 0 0 30px;
        }
    }
    .ms-button {
        text-transform: uppercase;
        font-weight: bold;

        &.mdc-button--raised {
            color: theme.$color-button-text;
        }
    }
    .modal-body,
    .modal-header {
        padding: 0;
        border: none;
    }

    .modal-header {
        margin-bottom: 25px;
        flex-wrap: wrap;
    }
}

.modal-lg,
.channels_container {
    .mat-mdc-dialog-surface {
        padding: 0;
    }
}

.modal-component {
    .modal-header {
        .title {
            font-size: 20px;
        }
        .failure_alert_container {
            display: block;
            width: 100%;
        }
    }

    .button-clear {
        position: absolute;
        right: 12px;
        top: 12px;

        .close_modal {
            display: flex;
            flex-direction: column;
            color: theme.$color-icon;
            background-color: transparent;
            width: auto;
            height: auto;
            align-items: center;
            font-size: 14px;
            padding: 0px 6px;

            .mat-icon {
                font-size: 24px;
                width: auto;
                height: auto;
                padding-top: 4px;
            }
            .mat-mdc-button-persistent-ripple,
            .mat-ripple {
                display: none;
            }
        }

        .mat-mdc-icon-button {
            line-height: 30px;
        }
    }
}

.modal-component,
.channels_container {
    mat-button-toggle-group {
        border-radius: 4px;
        box-shadow: none;
        border: 1px solid theme.$color-border-primary;
        .mat-button-toggle-checked {
            background-color: theme.$color-toggle-background;
            .mat-button-toggle-label-content {
                color: theme.$color-action-items-highlight;
            }
        }
        .mat-button-toggle-label-content {
            font-size: 14px;
            text-transform: uppercase;
            font-weight: bold;
            font-stretch: normal;
            font-style: normal;
            line-height: 38px;
            letter-spacing: 1.25px;
            color: theme.$color-font-text-content;
        }
    }
}

/* ------------ create modal form ------------ */
.modal-lg {
    .modal-header {
        display: flex;
    }

    .modal-dialog {
        .modal-header {
            border: none;
            display: flex;

            .header_image {
                height: 50px;
            }

            .header_content {
                border-bottom: 1px solid theme.$color-border-secondary;
                padding-bottom: 15px;
                box-sizing: content-box;
                &.author_details {
                    mat-grid-tile.post_details figure {
                        padding-left: -10px;
                    }
                }
                &.forum_title_container {
                    mat-grid-tile.post_details figure {
                        padding-left: 0;
                    }
                }
            }
            .post_dates {
                font-size: 12px;
                .label {
                    width: 100px;
                    display: inline-block;
                }
            }
            mat-grid-tile figure {
                justify-content: flex-start !important;
            }

            mat-grid-tile.post_details figure {
                padding-left: 10px;
            }
        }
        .modal-content {
            display: table-cell;
            position: relative;
            border-radius: 0;
            border: none;
            height: 100vh;
        }
    }

    .header_details {
        .title {
            font-size: 24px;
            color: theme.$color-font-text-content;
        }

        .breadcrumbs {
            font-size: 12px;
            line-height: 1.5;
            color: theme.$color-font-text-content;
        }

        .info {
            margin-top: 12px;

            small {
                font-size: 10px;
            }
        }
    }

    .mat-mdc-checkbox label {
        display: inline-flex;
    }

    ul {
        &.tags_category_list {
            padding: 0 3px;

            > li {
                > div {
                    background-color: variables.$white_color;
                    margin-bottom: 8px;
                    border-radius: 6px;
                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.08);
                }
            }
        }
    }

    .tags_container {
        margin-top: 14px;
        border-radius: 4px;
    }

    .selectedTags {
        display: inline-flex;

        .note {
            padding: 0 14px;
            min-width: 115px;
        }
    }

    .sort-items {
        display: inline-flex;

        label {
            display: inline-flex;
            text-transform: capitalize;
            font-size: 14px;
            font-weight: 400;
            margin-right: 12px;
            color: theme.$color-font-text-content;
        }
    }

    hr {
        border-top: 1px solid theme.$color-border-primary;
        margin: 40px 0 25px 0 !important;
    }

    .sortBy {
        &.btn {
            label {
                padding: 0.4rem 1rem;
            }
        }
    }
}

.modal-content {
    background-color: theme.$color-background-modal;
}

.modal-open .modal {
    z-index: 10000 !important;

    &.reader_modal {
        z-index: 100001 !important;

        .modal-dialog {
            height: 100%;
        }
    }

    &.search_from_modal {
        z-index: 10001 !important;
    }

    &.reader_modal {
        z-index: 100001 !important;
    }

    &.preview_modal {
        z-index: 100002 !important;
    }
}

.modal-open .modal-backdrop {
    z-index: 999 !important;
}

.cdk-overlay-container {
    z-index: 100000;
}

.cdk-global-scrollblock {
    overflow-y: hidden;
}

@media #{variables.$mobile} {
    .modal-sm .mat-mdc-dialog-surface {
        padding: 16px;
    }
}
