@use 'theming/theme';
@use '@angular/material' as material;

/* ------------ Material components ------------ */
.mat-mdc-unelevated-button.mat-primary,
.mat-mdc-raised-button.mat-primary,
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
    background-color: theme.$color-button-active-background;
}
.mat-mdc-raised-button {
    text-transform: uppercase;
}
mat-icon.mat-icon,
.mat-mdc-unelevated-button > mat-icon.mat-icon,
.mat-mdc-raised-button > mat-icon.mat-icon,
.mat-mdc-outlined-button > mat-icon.mat-icon {
    font-size: 17px;
    overflow: initial;

    &.size-auto {
        width: auto;
        height: auto;
    }
}
.mat-mdc-form-field .mat-icon {
    width: auto;
    height: auto;
}

.app-mat-form-field {
    width: 100%;
    margin-bottom: 8px;

    .mat-mdc-form-field-required-marker {
        color: theme.$color-alert;
    }

    .form-control:focus {
        background-color: transparent;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px theme.$color-background-secondary inset !important;
    }

    textarea {
        min-height: 50px;
    }
}
.mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab__text-label,
.mat-mdc-tab-link:not(.mat-mdc-tab-disabled) .mdc-tab__text-label {
    text-transform: uppercase;
    font-weight: bold;
    min-width: auto;
    opacity: 1;
    color: theme.$color-font-text-content;
    letter-spacing: 0.01em;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled).mdc-tab--active .mdc-tab__text-label {
    color: theme.$color-action-items-highlight;
}

.mat-mdc-tab-group.mat-accent .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline {
    border-color: theme.$color-action-items-highlight;
}

.mat-mdc-progress-spinner,
.mat-mdc-progress-spinner {
    &.mat-primary {
        circle {
            stroke: theme.$color-font-text-content;
        }
    }

    &.mat-secondary {
        circle {
            stroke: theme.$color-spinner-light;
        }
    }
}

.mat-mdc-menu-panel.mat-mdc-menu-panel {
    &.date-filter-menu {
        max-width: none;

        .mat-datepicker-toggle {
            color: theme.$color-action-items-highlight;
        }

        .mat-date-range-input-inner[disabled]::placeholder {
            color: rgba(0, 0, 0, 0.38);
        }

        .mat-mdc-form-field-subscript-wrapper {
            display: none;
        }

        .mdc-text-field--disabled.mdc-text-field--filled {
            background-color: transparent;
        }

        .mat-mdc-form-field-input-control.mat-mdc-form-field-input-control {
            line-height: 23px;
        }

        .mat-mdc-button-persistent-ripple {
            display: none;
        }
    }

    &.bulk-action-menu {
        .mat-mdc-menu-item {
            .mat-icon {
                margin: 0px 11px 0 5px;
                font-size: 18px;
            }

            &.mark-all-as-btn {
                .mat-icon {
                    font-size: 17px;
                }
            }

            &.exclude-all-item {
                .mat-icon {
                    margin-right: 13px;
                }
            }
        }
    }
    &.posts-filter-menu {
        .mat-mdc-menu-item {
            &.date-filter-item {
                padding: 0;
            }
        }

        .mat-mdc-checkbox {
            height: 48px;
            width: 100%;

            .mdc-form-field {
                height: 48px;

                & > label {
                    height: 48px;
                    line-height: 48px;
                    font-size: 16px;
                }
            }
        }
    }

    &.ai-menu,
    &.posts-filter-menu,
    &.bulk-action-menu {
        .mat-mdc-menu-item {
            min-height: 48px;
        }
    }

    &.include-post-menu {
        .header {
            font-size: 14px;
            color: theme.$color-font-text-content;
            padding: 0.5em 1em;
            line-height: 18px;
        }
        .do-not-show-again {
            font-size: 13px;
            color: theme.$color-action-items-highlight;
            padding: 0.5em 1em;
            cursor: pointer;
        }
    }
}

.mat-mdc-menu-panel {
    margin-right: 33px;
    min-height: 56px !important;

    .mat-mdc-menu-item {
        min-height: 28px;
        .mdc-list-item__primary-text {
            height: 28px;
            display: flex;
            align-items: center;
            font-size: 14px;
            color: theme.$color-font-text-content;
        }

        &.single {
            margin-top: 14px;
        }

        .mat-mdc-menu-item-text,
        .mat-mdc-menu-item-text a {
            display: block;
            width: 100%;
            line-height: 28px;
        }

        a {
            color: theme.$color-font-text-content;
        }
    }

    .menu-wrapper {
        padding: 16px 0;
    }

    .menu-block-divider {
        height: 16px;
    }

    .menu-header {
        text-transform: uppercase;
        padding: 0 16px;
        line-height: 28px;
        color: theme.$color-font-text-content;
    }

    .mat-mdc-menu-content {
        min-width: 220px;

        &:not(:empty) {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.allowed-queries-menu {
        .mat-mdc-menu-item {
            &-text {
                display: flex;
                justify-content: space-between;
            }
            .mat-icon {
                margin: 0;
                height: auto;
                width: auto;
            }
        }
    }

    &.crawl-error-menu {
        .mat-mdc-menu-item {
            min-height: 28px;
            padding: 8px 12px;
            border-bottom: 1px solid theme.$color-border-primary;
        }
        .search-name {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 8px;
        }
        .search-error {
            font-size: 12px;
            color: theme.$color-font-hint;

            .mat-icon {
                color: theme.$color-crawled-disabled-warning;
                font-size: 12px;
                margin-right: 2px;
            }
        }
    }
}

.mat-mdc-list-item .mdc-list-item__primary-text {
    line-height: 1.5;
}

.mat-drawer-content {
    overflow-y: hidden !important;
}

.mdc-checkbox__ripple,
.mat-mdc-checkbox-ripple {
    display: none;
}

.accordion-button {
    &:focus {
        border: none;
        z-index: initial;
        box-shadow: none;
    }
    &:not(.collapsed) {
        color: inherit;
        background-color: inherit;
        box-shadow: none;
    }
}

.mat-mdc-select-value {
    line-height: 24px;
}

/* ------------ Silo select panel ------------ */
.mat-mdc-select-panel.mat-primary.silo-select {
    background-color: theme.$color-login-form-background;
    .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
    .mdc-list-item__primary-text {
        color: theme.$color-font-secondary;
    }

    .mat-mdc-option:hover:not(.mat-option-disabled),
    .mat-option:focus:not(.mat-option-disabled),
    .mat-mdc-option:hover:not(.mdc-list-item--disabled),
    .mat-mdc-option:focus:not(.mdc-list-item--disabled),
    .mat-mdc-option.mat-mdc-option-active,
    .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
        background-color: theme.$color-silo-select-hover-background;
    }
}

.mat-mdc-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
    background-color: theme.$color-button-active-background;
}

.mat-mdc-tab-group .mat-mdc-tab .mdc-tab__content {
    pointer-events: all;
}

.mat-mdc-tab-header {
    border-bottom: 1px solid theme.$color-section-divider-light;
}

.mat-mdc-tab-group.mat-accent .mdc-tab__ripple::before {
    background-color: transparent;
}

.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow,
.mdc-text-field--focused:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-floating-label {
    color: theme.$color-font-text-content;
}

:root {
    @include material.checkbox-density('minimum');
    --mdc-theme-primary: theme.$color-font-text-content;
}

/* ------------ Admin silo select ------------ */
.mat-mdc-select-panel.mat-primary.admin-silo-select {
    .mat-mdc-option {
        min-height: 36px;
    }

    .mdc-list-item__primary-text {
        font-size: 12px;
    }

    .mat-mdc-option-pseudo-checkbox {
        display: none;
    }

    &.dark-with-shadow {
        background-color: theme.$color-dark-dropdown-shadow;
        padding-bottom: 0;
        padding-top: 4px;
        box-shadow:
            3px 5px 5px -3px theme.$color-dark-dropdown-shadow,
            0px 8px 10px 1px theme.$color-dark-dropdown-shadow,
            0px 3px 14px 2px theme.$color-dark-dropdown-shadow;
        .mat-mdc-option {
            background-color: theme.$color-background-primary;
            border-bottom: 1px solid theme.$color-font-category-name;

            &:hover,
            &.mdc-list-item--selected {
                background-color: theme.$color-left-menu-channel-background-active;
            }
            .mat-icon {
                margin-right: 0;
            }
        }
        .mat-mdc-option-pseudo-checkbox,
        .silo-selected,
        .mdc-list-item__primary-text {
            color: theme.$color-font-item-active;
        }
    }
}
.mat-mdc-select-panel.mat-primary.admin-silo-select,
.mat-mdc-select-panel.mat-primary.silo-select {
    .select-search {
        background-color: theme.$color-background-primary;
        border-bottom: 2px solid theme.$color-action-items-highlight;
        input {
            color: theme.$color-action-items-highlight;
            width: 100%;
            background: transparent;
            font-size: 13px;
            padding: 8px 14px;
            outline: none;
        }
    }

    .no-results {
        padding: 8px 16px;
        text-align: center;
        color: theme.$color-font-secondary;
        font-size: 14px;
    }
}

.app-select-option.mat-mdc-option .mdc-list-item__primary-text {
    line-height: 22px;
}

.mat-mdc-select-panel.credit-logs-filter {
    .clear-collections-filter {
        font-size: 16px;
        letter-spacing: 0.1px;
        width: 100%;
        height: 48px;
        justify-content: start;
        padding-left: 11px;

        .button-text {
            vertical-align: middle;
            padding-left: 10px;
        }
    }
}

/* ------------ User menu ------------ */
.user_menu {
    &.mat-mdc-menu-panel .mat-mdc-menu-item .mdc-list-item__primary-text {
        width: 100%;

        a {
            line-height: 28px;
            width: 100%;
        }
    }
}

.mat-mdc-icon-button.mat-mdc-button-base {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.mat-expansion-panel:not([class*='mat-elevation-z']) {
    box-shadow:
        0 3px 1px -2px #0003,
        0 2px 2px #00000024,
        0 1px 5px #0000001f;
}

.mat-mdc-snack-bar-container {
    &.error {
        .mdc-snackbar__surface {
            background: theme.$color-alert-background;
            border: 2px solid theme.$color-alert;
        }

        .mdc-snackbar__label {
            color: theme.$color-alert;
        }
    }
}

/* ------------ Channels select ------------ */
.channels-list-dropdown {
    .collection {
        .mat-mdc-option {
            padding-left: 44px;
        }

        &-name {
            padding-left: 16px;
            user-select: none;
            line-height: 32px;
        }

        &-icon {
            border: 1px solid theme.$color-font-text-content;
            position: relative;
            border-radius: 3px;
            width: 16px;
            height: 13px;
            display: inline-block;
            margin-right: 6px;

            .mat-icon {
                left: -2px;
                position: absolute;
                top: -12px;
                width: 14px;
                height: 21px;
                background-color: theme.$color-background-modal;
                font-size: 14px;
            }
        }
    }

    .category {
        &-name {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 0 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            cursor: default;
            text-transform: uppercase;
            line-height: 32px;

            &::before,
            &::after {
                content: '';
                display: inline-block;
                height: 1px;
                background-color: theme.$color-font-text-content;
            }
            &::before {
                width: 11px;
            }
            &::after {
                flex-grow: 1;
            }

            .name {
                font-size: 12px;
                font-weight: bold;
                padding: 0 7px;
                opacity: 0.8;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 80%;
            }
        }
    }
}

/* ------------ Form field error ------------ */

.mat-mdc-form-field-subscript-wrapper {
    .mat-mdc-form-field-error-wrapper {
        position: relative;
        top: -15px;
    }
}
