@use 'fonts';
@use 'material.theme';
@use 'global';

.loader_outer {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.loader_inner {
    display: table-cell;
    vertical-align: middle;
    font-weight: bold;

    > img {
        display: block;
        margin: 0 auto 10px;
    }
}
